import { TeamRes } from "@/api/game";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "@/components/ui/drawer";
import { faChevronDown, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";

// To be used inside a <Drawer>

export default function ToolsList({ teams }: { teams: TeamRes[] }) {
  const spinRef = useRef<HTMLDivElement>(null);
  function spin() {
    const circleElements = teamsRef.current;
    let dangle = 360 / circleElements.length;

    const angle = [...Array(11).keys()]
      .map(() => Math.floor(Math.random() * circleElements.length) * dangle)
      .reduce((a, b) => a + b, 0);
    spinRef.current?.animate(
      [
        { transform: `rotate(0deg)` },
        { transform: `rotate(${angle + 270}deg)` },
      ],
      {
        duration: 2500,
        easing: "ease-out",
        fill: "forwards",
      },
    );
  }

  // https://stackoverflow.com/a/62466233
  const circleRef = useRef<HTMLDivElement>(null);
  const teamsRef = useRef<Array<HTMLDivElement | null>>(Array(teams.length));
  function setTeamCircle() {
    const teamCircle = circleRef.current;
    if (!teamCircle) return;
    const circleElements = teamsRef.current;

    let angle = 180;
    let dangle = 360 / circleElements.length;

    for (let i = 0; i < circleElements.length; i++) {
      let circle = circleElements[i];
      if (!circle) return;
      angle += dangle;
      circle.style.transform = `
      translateX(50%) translateY(50%) rotate(${angle}deg) translate(${
        teamCircle.clientWidth / 3
      }px) rotate(-${angle}deg)
      `;
    }
  }

  return (
    <DrawerContent>
      <h2 className="text-xl font-medium text-center">Tools</h2>
      <div className="max-h-[60vh] overflow-y-scroll">
        <Collapsible onOpenChange={setTeamCircle}>
          <div className="border border-brown m-2 rounded-xl drop-shadow p-2">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">Who's First?</h3>
              <CollapsibleTrigger asChild>
                <Button variant="iconAlt" size="iconAlt">
                  <FontAwesomeIcon icon={faChevronDown} />
                </Button>
              </CollapsibleTrigger>
            </div>
            <p className="text-sm">
              Bickering over who starts? Pick a random player.
            </p>
            <CollapsibleContent>
              <div ref={circleRef} className="relative pt-[100%]">
                <div className="absolute right-2/4 translate-x-2/4 bottom-2/4 h-16 w-2">
                  <div
                    ref={spinRef}
                    className="h-full w-full bg-black rounded-full origin-bottom"
                  ></div>
                </div>
                <Button
                  variant="icon"
                  size="icon"
                  onClick={spin}
                  className="absolute right-2/4 translate-x-2/4 bottom-2/4 translate-y-2/4"
                >
                  <FontAwesomeIcon icon={faRotate} />
                </Button>
                {teams.map((t, i) => (
                  <div
                    key={t.id}
                    ref={(ref) => (teamsRef.current[i] = ref)}
                    className="absolute right-2/4 bottom-2/4"
                  >
                    {t.users.map((u) => (
                      <Avatar key={u.id}>
                        <AvatarImage src={u.avatarUrl} />
                      </Avatar>
                    ))}
                  </div>
                ))}
              </div>
            </CollapsibleContent>
          </div>
        </Collapsible>
        <div className="grid auto-rows-fr grid-cols-2 gap-1">
          <div className="border border-brown m-2 rounded-xl drop-shadow p-2">
            <h3 className="text-lg font-medium">Coin flip</h3>
            <CoinToss />
          </div>
          <div className="border border-brown m-2 rounded-xl drop-shadow p-2 flex items-center">
            <h3 className="text-lg font-medium text-center">
              More Coming Soon!
            </h3>
          </div>
        </div>
      </div>
      <DrawerFooter>
        <DrawerClose asChild>
          <Button variant="outline" size="sm">
            Close
          </Button>
        </DrawerClose>
      </DrawerFooter>
    </DrawerContent>
  );
}

function CoinToss() {
  const ref = useRef<HTMLDivElement>(null);

  function flip() {
    let n = Math.floor(Math.random() * 5) + 3;
    ref.current?.animate(
      [{ transform: `rotateY(0deg)` }, { transform: `rotateY(${180 * n}deg)` }],
      {
        duration: 1500,
        easing: "ease-out",
        fill: "forwards",
      },
    );
  }

  return (
    <div className="flex justify-center">
      <div
        ref={ref}
        onClick={flip}
        className="relative h-16 w-16 cursor-pointer [transform-style:preserve-3d]"
      >
        <div className="absolute h-full w-full bg-brown z-20 rounded-full drop-shadow-md [backface-visibility:hidden]">
          <div className="h-full flex justify-center items-center">
            <img src="/images/shield_orange.svg" className="w-6" />
          </div>
        </div>
        <div className="absolute h-full w-full bg-orangeLight z-30 rounded-full drop-shadow-md [backface-visibility:hidden] [transform:rotateY(-180deg)]">
          <div className="h-full flex justify-center items-center">
            <img src="/images/shield_brown.svg" className="w-6" />
          </div>
        </div>
      </div>
    </div>
  );
}
