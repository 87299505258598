import { ReactNode } from "react";
import { Button } from "./button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

export default function NavBar(props: {
  children?: ReactNode;
  logo?: boolean;
  home?: boolean;
  back?: string;
  historyBack?: boolean;
}) {
  return (
    <div className={clsx(props.logo ? "h-32" : "pb-4", "relative")}>
      <div className="flex justify-between items-center">
        {props.back && (
          <Button variant="icon" size="icon" asChild>
            <Link to={props.back}>
              <FontAwesomeIcon icon={faChevronLeft} className="text-3xl" />
            </Link>
          </Button>
        )}
        {props.children}
        {props.home && (
          <Button variant="icon" size="icon" asChild>
            <Link to="/home">
              <FontAwesomeIcon icon={faXmark} className="text-3xl" />
            </Link>
          </Button>
        )}
      </div>
      {props.logo && (
        <Link to="/home">
          <img
            src="/pocket_tally_logo.png"
            alt="pocket tally logo"
            className="absolute top-0 right-2/4 translate-x-2/4 translate-y-2/4 w-16 h-16"
          />
        </Link>
      )}
    </div>
  );
}
